import styled from '@emotion/styled';
import { topNavigationMenu } from './topNavigationMenu.css';

export const TopNavigationMenuStyled = styled.nav`
  ${topNavigationMenu} /* computed styles */
    /* computed styles -- add props if you wish -- hint: Look at PageSection and PageSectionStyled */
  .navigation-link {
    font-size: 18px;
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    .desktop-navigation {
      a {
        margin-right: 15px;
      }

      .navBarLink:not(:last-child),
      .navigation-link:not(:last-child) {
        margin-right: 15px;
      }
    }

    .navigation-link {
      font-size: 13px;
      margin-right: 15px;
    }
  }

  @media screen and (min-width: 1101px) and (max-width: 1199px) {
    .desktop-navigation {
      a {
        margin-right: 15px;
      }

      .navBarLink:not(:last-child),
      .navigation-link:not(:last-child) {
        margin-right: 15px;
      }
    }

    .navigation-link {
      font-size: 16px;
      margin-right: 15px;
    }
  }

  @media screen and (min-width: 1199px) and (max-width: 1399px) {
    .desktop-navigation {
      a {
        margin-right: 15px;
      }

      .navBarLink:not(:last-child),
      .navigation-link:not(:last-child) {
        margin-right: 15px;
      }
    }

    .navigation-link {
      font-size: 16px;
      margin-right: 15px;
    }
  }

  @media screen and (min-width: 1400px) {
    .navigation-link {
      font-size: 14px;
      margin-right: 0px;
    }
  }

  @media screen and (min-width: 2200px) {
    .navigation-link {
      font-size: 24px;
    }
  }

  /* @media screen and (max-width: 1220px) {
    .navigation-link {
      font-size: 15px;
      margin-right: 5px;
    }

    .desktop-navigation {
      .navBarLink:not(:last-child),
      .navigation-link:not(:last-child) {
        margin-right: 5px;
      }
    }
  } */
`;
export const MobileContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  right: 0;
  flex-direction: column;
  background-color: white;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 30px;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;
