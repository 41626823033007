import { css } from '@emotion/core';
import { theme } from '../../../../../layouts/main.layout';
import styled from '@emotion/styled';

export const border = css`
  // border-bottom: solid 1px ${theme.colors.borderGray};
  background-color: white;
`;

export const LinkStyle = styled.a`
  color: ${theme.colors.primaryTextGray};
  font-weight: ${theme.fontWeights.bold};
  &:hover {
    color: ${theme.colors.primaryTextGray};
    text-decoration: none;
  }
`;

export const underline = css`
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;

export const displayNone = css`
  @media screen and (max-width: 640px) {
    display: none;
  }
`;
