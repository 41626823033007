import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { Box, Flex, Text } from 'rebass';
import { useMatch } from '@reach/router';
import { LanguageGuard } from '../../LanguageGuard/LanguageGuard';
import { navBarLink, navBarSpan, navButtons } from './css/topNavigationMenu.css';
import { theme } from '../../../../layouts/main.layout';

const DesktopTopNav: React.FC = () => {
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const LANG = i18n.language;

  const navLinks = [
    {
      title: t('home_Nav'),
      to: t('link_home'),
    },
    {
      title: t('partnership_Nav'),
      to: t('link_partners'),
    },
    {
      title: t('news_Nav'),
      to: t('link_news'),
      visible: [{ country: 'me' }, { country: 'ba' }, { country: 'mk' }, { country: 'si' }],
    },
    {
      title: t('blog_Nav'),
      to: t('/ro/blog'),
      visible: [{ country: 'ro' }],
    },
    {
      title: t('promo/news_Nav'),
      to: '#',
      visible: [{ country: 'rs', lang: 'sr' }, { country: 'hr' }],
      subLinks: [
        {
          title: t('blog_Nav'),
          to: `/${LANG}/blog/`,
        },
        {
          title: t('news_Nav'),
          to: t('link_news'),
        },
        {
          title: t('promo_Nav'),
          to: t('link_promo'),
        },
      ],
    },
    {
      title: t('about_Nav'),
      to: t('link_about'),
    },
  ];

  const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, subLinks: any) => {
    if (subLinks) {
      e.preventDefault();
    }
  };

  return (
    <Flex className="desktop-navigation">
      {navLinks.map((link) => {
        const Component = link.subLinks ? Flex : Link;
        const border = { borderBottom: `solid 3px ${theme.colors.vozziOrange}` };
        let childActive = false;

        if (link.subLinks) {
          childActive = link.subLinks.some((link) => useMatch(link.to));
        }

        return (
          <LanguageGuard {...link} key={link.to}>
            <Component
              activeStyle={border}
              style={childActive ? border : {}}
              css={navBarLink}
              to={link.to}
              className={link.subLinks ? 'navBarLink dropdown' : 'navBarLink'}
              onClick={(e: any) => onLinkClick(e, link.subLinks)}
            >
              <Flex css={navButtons} height="90px">
                <Text
                  className="navigation-link"
                  style={{
                    textTransform: 'capitalize',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '160%',
                    letterSpacing: '0.03em',
                    color: '#1E1E23',
                  }}
                >
                  {link.title}
                </Text>
                <Text css={navBarSpan}>{link.subTitle}</Text>
              </Flex>
              {link.subLinks && <SubLinks links={link.subLinks} />}
            </Component>
          </LanguageGuard>
        );
      })}
    </Flex>
  );
};

const SubLinks: React.FC<any> = ({ links }) => (
  <Box className="dropdown-content">
    {links.map((link: any) => (
      <Link to={link.to} key={link.title}>
        {link.title}
      </Link>
    ))}
  </Box>
);

export default DesktopTopNav;
