import styled from 'styled-components';
import { COUNTRY } from '../../../../configs/env';

export const Footer = styled.footer`
  height: auto;
  position: relative;

  @media screen and (max-width: 768px) {
    .footer-cards-holder {
      /* bottom: -160px !important; */
    }

    .not-show-on-mobile {
      display: none;
    }
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    .footer-cards-holder {
      flex-wrap: wrap;
      max-width: 500px !important;
    }

    .not-show-on-mobile {
      display: none;
    }
  }

  @media screen and (min-width: 901px) {
    .footer-cards-holder {
      display: flex;
      align-items: stretch;
    }
  }

  @media screen and (max-width: 500px) {
    .footer-cards-holder {
      bottom: -20px !important;
    }
  }

  @media screen and (min-width: 1400px) {
    .footer-cards-holder {
      max-width: 1000px;
      margin: 0 auto;

      h2 {
        font-size: 24px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .footer-cards-holder {
      max-width: 1100px;
      margin: 0 auto;

      h2 {
        font-size: 24px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  @media screen and (min-width: 1600px) {
    .footer-cards-holder {
      max-width: 900px;
      margin: 0 auto;

      h2 {
        font-size: 24px;
      }

      p {
        font-size: 18px;
      }
    }
  }
  @media screen and (min-width: 2000px) {
    .footer-cards-holder {
      bottom: -180px !important;
      max-width: 1300px;
      h2 {
        font-size: 24px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  @media screen and (min-width: 2200px) {
    .country-select {
      font-size: 28px;
    }
    .footer-cards-holder {
      bottom: -180px !important;
      max-width: 1400px;
      h2 {
        font-size: 44px;
        font-weight: ${COUNTRY === 'mk' ? 600 : null};
      }

      p {
        font-size: 26px;
      }
    }
  }
`;

export const FooterTopSection = styled.div<any>`
  background-size: 100% auto !important;
  height: auto;
  padding: 120px 300px;
  padding-bottom: 0;
  background: ${(props: any) => `url('${props.backgroundImage}') no-repeat` || null};

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    padding: 60px 50px;

    h2 {
      font-size: 28px;
    }

    p {
      font-size: 16px;
      min-height: 50px;
    }

    .footer-cards-holder {
      bottom: -100px !important;
      max-width: 1100px;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    background-size: auto 100% !important;
    background-position: right !important;
  }

  @media screen and (min-width: 1600px) {
    background-position-y: center;
  }

  /* @media screen and (max-width: 1792px) {
    padding: 120px 250px;
    padding-bottom: 0px;
    background-position-y: center;
  } */

  @media screen and (max-width: 768px) {
    padding: 40px 20px;
    background: ${(props: any) => `url('${props.mobileBackgroundImage}') no-repeat` || null};
    background-size: cover !important;
    max-height: 650px;
  }

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    background-size: auto 100% !important;
  }

  @media screen and (max-width: 500px) {
    background-size: cover !important;
  }

  @media screen and (min-width: 2000px) {
    padding: 120px 200px;
    background-position-y: center;
  }
`;

export const FooterBottomSection = styled.div`
  height: auto;
  background-color: #1e1e23;
  padding: 185px 300px;

  @media screen and (min-width: 450px) and (max-width: 769px) {
    padding: 60px 50px;
    padding-top: 250px;
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    padding: 60px 50px;
  }

  /* @media screen and (max-width: 1792px) {
    padding: 185px 250px;
    padding-bottom: 0px;
  } */

  @media screen and (max-width: 450px) {
    padding: 40px 20px;
    padding-top: 250px;
  }

  @media screen and (min-width: 1600px) {
    padding: 185px 200px;
    padding-bottom: 0px;
  }

  @media screen and (min-width: 2000px) {
    padding: 120px 200px;
  }
`;

export const FooterTitle = styled.h2`
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 130%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 80px;
`;

export const FooterAboveTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 15px;
`;

export const FooterCard = styled.div`
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 16.93%, rgba(255, 255, 255, 0) 162.81%);
  backdrop-filter: blur(8px);
  width: 605px;
  height: 443px;
  border-radius: 20px;
  padding: 50px 100px;
  text-align: center;

  @media screen and (min-width: 450px) and (max-width: 769px) {
    width: 360px !important;
    padding: 30px !important;
  }

  @media screen and (min-width: 770px) and (max-width: 779px) {
    padding: 35px 50px;
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    width: 420px;
    padding: 50px 50px;
    height: 400px;
  }

  @media screen and (min-width: 1199px) and (max-width: 1399px) {
    padding: 30px;
    width: 500px;
    height: 380px;
  }

  @media screen and (min-width: 1400px) {
    padding: 30px;
    height: auto;
    width: auto;

    p {
      max-width: 360px;
    }
  }

  @media screen and (min-width: 2000px) {
    padding: 30px;
    height: auto;
    width: 550px;

    p {
      max-width: 360px;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 2200px) {
    padding: 30px;
    height: auto;
    width: 650px;

    p {
      max-width: 450px;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 30px;
    height: auto;
    margin-bottom: 20px;
  }
`;

export const FooterCardIcon = styled.img`
  margin: 0 auto;

  @media screen and (min-width: 2200px) {
    height: 140px !important;
  }
`;

export const FooterCardTitle = styled.h2`
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 125%;
  text-align: center;
  color: #1e1e23;
  margin-top: 25px;
  margin-bottom: 15px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const FooterCardDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 160%;
  text-align: center;
  color: #1e1e23;
  opacity: 0.8;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const BottomSectionLeftCol = styled.div`
  width: 45%;
  flex: 0 0 45%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const BottomSectionRightCol = styled.div`
  width: 40%;
  flex: 0 0 40%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FooterSectionLogo = styled.img`
  width: 320px;
  margin-bottom: 80px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    max-width: 260px;
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    max-width: 200px;
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    width: 250px;
  }
`;

export const FooterBottomSectionLeftColDescription = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.02em;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    display: none;
  }

  @media screen and (min-width: 2200px) {
    font-size: 22px;
  }
`;

export const SocialIconsList = styled.div`
  display: block;
  position: relative;
`;

export const SocialIconsListItem = styled.a`
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.02em;
  color: #ffffff;
  width: 100%;
  cursor: pointer;
  transition: ease 0.4s;

  :hover {
    color: #fe7f12;
  }

  :hover img.arrow {
    transition: ease 0.4s;
    cursor: pointer;
    transform: translateX(4px);
  }

  @media screen and (max-width: 768px) {
    padding: 10px 0px;
    font-size: 14px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 22px;

    img {
      width: 40px;
    }
  }
`;

export const SocialIcon = styled.img`
  width: 20px;
  height: auto;
  margin-right: 20px;

  @media screen and (min-width: 2200px) {
    width: 40px;
  }
`;

export const SocialIconLink = styled.img`
  width: auto;
`;

export const BottomSectionRightColTitle = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 125.5%;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    font-size: 32px;
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    font-size: 32px;
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    font-size: 40px;
  }
`;

export const LinksList = styled.div`
  width: 50%;
  flex: 0 0 50%;
  display: block;
  position: relative;
  @media screen and (max-width: 768px) {
    margin-top: 40px;
  }
`;

export const LinksListItem = styled.div`
  :first-of-type {
    color: #ffffff;
    text-transform: uppercase;
    opacity: 0.2;
  }

  margin-bottom: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.03em;
  color: #ffffff;

  a {
    color: inherit;
  }

  a:hover {
    color: #fe7f12;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (min-width: 2200px) {
    font-size: 26px;
  }
`;

export const FooterIcon = styled.img`
  width: 100px;

  @media screen and (max-width: 768px) {
    width: 60px;
    margin-right: 0;
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    max-width: 100px;
    width: 60px;
  }

  @media screen and (min-width: 2200px) {
    width: 200px;
  }
`;

export const CopyRightText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    font-size: 10px;
    margin-bottom: 0;
  }

  @media screen and (min-width: 2200px) {
    font-size: 22px;
  }
`;
