import React from 'react';
import { Link } from 'gatsby';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Image, Text } from 'rebass';

import { LanguageGuard } from '../../LanguageGuard/LanguageGuard';
import { theme } from '../../../../layouts/main.layout';
import { navbar } from '../../../../shared-state/main';
import { MobileContainer } from './css/TopNavigationMenuStyled.css';
import { navBarLink, navBarScroll, navBarSpan, navButtons } from './css/topNavigationMenu.css';

const MobileTopNav: React.FC = () => {
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const LANG = i18n.language;

  const [navbarState, SetNavbarState] = useRecoilState(navbar);

  // TO-DO: disable links based on language
  const navLinks = [
    {
      title: t('home_Nav'),
      subTitle: t('home_SubNav'),
      to: t('link_home'),
    },
    {
      title: t('partnership_Nav'),
      subTitle: t('partnership_SubNav'),
      to: t('link_partners'),
    },
    {
      title: t('blog_Nav'),
      subTitle: t('blog_SubNav'),
      to: `/${LANG}/blog/`,
      visible: [{ country: 'rs', lang: 'sr' }, { country: 'hr' }, { country: 'si' }],
    },
    {
      title: t('blog_Nav'),
      subTitle: 'Събития',
      to: t('link_blog'),
      visible: [{ country: 'bg' }],
    },
    {
      title: t('news_Nav'),
      subTitle: 'Новини',
      to: t('link_news'),
      visible: [{ country: 'bg' }],
    },
    {
      title: t('news_Nav'),
      subTitle: t('news_SubNav'),
      to: t('link_news'),
      visible: [
        { country: 'rs', lang: 'sr' },
        { country: 'me' },
        { country: 'ba' },
        { country: 'mk' },
        { country: 'hr' },
        { country: 'si' },
      ],
    },
    {
      title: t('promo_Nav'),
      subTitle: t('promo_SubNav'),
      to: t('link_promo'),
      visible: [{ country: 'rs', lang: 'sr' }, { country: 'hr' }, { country: 'si' }],
    },
    {
      title: t('about_Nav'),
      subTitle: t('about_SubNav'),
      to: t('link_about'),
    },
  ];

  return (
    <Box>
      <Image
        py={4}
        src="/img/hamburger-icon.png"
        onClick={() =>
          SetNavbarState(() => ({
            isOpen: !navbarState.isOpen,
          }))
        }
        display={{ _: 'block', lg: 'none' }}
      />
      <MobileContainer isOpen={navbarState.isOpen}>
        <Box css={navBarScroll}>
          {navLinks.map((link) => {
            return (
              <LanguageGuard key={link.to} {...link}>
                <Link
                  css={navBarLink}
                  to={link.to}
                  onClick={() =>
                    SetNavbarState(() => ({
                      isOpen: !navbarState.isOpen,
                    }))
                  }
                >
                  <Flex css={navButtons} height="50px">
                    <Text color={theme.colors.darkGray} style={{ textTransform: 'uppercase' }} fontWeight={theme.fontWeights.bold}>
                      {link.title}
                    </Text>
                    <Text css={navBarSpan} fontWeight={theme.fontWeights.bold}>
                      {link.subTitle}
                    </Text>
                  </Flex>
                </Link>
              </LanguageGuard>
            );
          })}
        </Box>
      </MobileContainer>
    </Box>
  );
};

export default MobileTopNav;
