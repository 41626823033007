/* eslint-disable no-prototype-builtins */
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { COUNTRY } from '../../../configs/env';

type Visibility = Array<{
  country?: string;
  lang?: string;
}>;

interface LanguageGuardProp {
  visible?: Visibility;
  hidden?: Visibility;
  children: JSX.Element;
}

// TO-DO: refactor this component
// TO-DO: change LanguageGuard name!!!
export const LanguageGuard = ({ visible = [], hidden = [], children }: LanguageGuardProp): ReactElement | null => {
  const { i18n } = useTranslation();

  // if we have visible prop values we want to hide component by default and show it in cases where visible prop is set
  // if there is no visible prop we are showing component by default and hide it where hidden prop is set
  // do not add both hidden and visible prop, it will create bugs
  let showComponent = visible.length === 0 ? true : false;

  if (visible.length > 0) {
    visible.forEach((vis) => {
      const visibleForCountry = vis.hasOwnProperty('country');
      const visibleForLang = vis.hasOwnProperty('lang');

      if (visibleForCountry && visibleForLang && !showComponent) {
        showComponent = i18n.language === vis.lang && COUNTRY === vis.country;
      } else if (!visibleForCountry && visibleForLang && !showComponent) {
        showComponent = i18n.language === vis.lang;
      } else if (visibleForCountry && !visibleForLang && !showComponent) {
        showComponent = COUNTRY === vis.country;
      }
    });
  }

  if (hidden.length > 0) {
    hidden.forEach((hid) => {
      const hiddenForCountry = hid.hasOwnProperty('country');
      const hiddenForLang = hid.hasOwnProperty('lang');

      if (hiddenForCountry && hiddenForLang && showComponent) {
        showComponent = i18n.language === hid.lang && COUNTRY === hid.country ? false : true;
      } else if (!hiddenForCountry && hiddenForLang && showComponent) {
        showComponent = i18n.language === hid.lang ? false : true;
      } else if (hiddenForCountry && !hiddenForLang && showComponent) {
        showComponent = COUNTRY === hid.country ? false : true;
      }
    });
  }

  if (showComponent) {
    return children;
  }

  return null;
};
