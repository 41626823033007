import React from 'react';
import { Link } from 'gatsby';
import { LogoWithNavStyled } from './css/LogoWithNavStyled.css';
import { VozziLogo } from '../Logo/logo.component';
import { TopNavigationMenu } from '../TopNavigationMenu/TopNavigationMenu.component';
import { Box, Flex } from 'rebass';
import { containerMobile, containerWithPadding } from '../../../../shared-styles/container.css';
import { border } from './css/logoWithNav.css';
// import { Sticky } from '../Sticky/Sticky';
import { ResponsiveContainer } from '../../../functional/ResponsiveContainer.functional';
// import { useRecoilState } from 'recoil';
// import { navbar } from '../../../../shared-state/main';
import { useTranslation } from 'react-i18next';
import { SimpleButtonQr } from '../../../../global/new-global-components';

export const LogoWithNav: React.FC<{ absPos?: boolean }> = (props) => {
  // const [navbarState, SetNavbarState] = useRecoilState(navbar);

  const { t } = useTranslation();

  return (
    <LogoWithNavStyled absPos={props.absPos}>
      <Box css={border}>
        <ResponsiveContainer breakpoint="sm">
          {(matches: any): JSX.Element => (
            <>
              {matches.mobile && (
                <Flex justifyContent="space-between" alignItems="center" css={containerMobile}>
                  <TopNavigationMenu />

                  <Link to={t('link_home')}>
                    <VozziLogo src="/vectors/vozzi-new-logo-svg.svg" />
                  </Link>

                  {/* <Box>
                    <LinkStyle style={{ fontSize: 14 }} href={`tel:${t('bundles_ContactSectionPhoneNum').split(' ').join('')}`}>
                      <Image style={{ width: 26 }} src="/img/phone-icon.png" />
                    </LinkStyle>
                  </Box> */}
                </Flex>
              )}
              {matches.desktop && (
                <Flex justifyContent="space-between" alignItems="center" css={containerWithPadding}>
                  <Link style={{ display: 'flex' }} to={t('link_home')}>
                    <VozziLogo src="/vectors/vozzi-new-logo-svg.svg" />
                  </Link>

                  <TopNavigationMenu />

                  <Box className="simple-button-qr-desktop">
                    {/* <LinkStyle style={{ fontSize: 14 }} href={`tel:${t('bundles_ContactSectionPhoneNum').split(' ').join('')}`}>
                      {t('menu_callCentar')}
                    </LinkStyle> */}
                    <SimpleButtonQr border="1.5px solid #1E1E23" padding="20px" color="#1E1E23">
                      <img className="svg-icon" style={{ marginRight: 20 }} src="/vectors/download.svg" />
                      {t('general_GetAppButtonTitle')}
                      <div style={{ backgroundColor: 'white', padding: 10 }}>
                        <img src="/new-images/qr-code-vozzi.jpeg" alt="qr-code" />
                      </div>
                    </SimpleButtonQr>
                  </Box>
                </Flex>
              )}
            </>
          )}
        </ResponsiveContainer>
      </Box>
      {/* {!navbarState.isOpen && (
        <LanguageGuard visible={[{ country: 'rs' }]}>
          <Sticky />
        </LanguageGuard>
      )} */}
    </LogoWithNavStyled>
  );
};
