import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import {
  AboveTitle,
  BadgeOrange,
  Divider,
  FlexWrapper,
  FlexWrapperNoAlign,
  FlexWrapperStart,
  Img,
  RegularLink,
  Title,
} from '../../../../global/new-global-components';
import {
  BottomSectionLeftCol,
  BottomSectionRightCol,
  BottomSectionRightColTitle,
  CopyRightText,
  Footer,
  FooterBottomSection,
  FooterBottomSectionLeftColDescription,
  FooterCard,
  FooterCardDescription,
  FooterCardIcon,
  FooterCardTitle,
  FooterIcon,
  FooterSectionLogo,
  FooterTopSection,
  LinksList,
  LinksListItem,
  SocialIcon,
  SocialIconLink,
  SocialIconsList,
  SocialIconsListItem,
} from './footer.styles';
import Select from 'react-select';
import { COUNTRY } from '../../../../configs/env';
import { LanguageGuard } from '../../LanguageGuard/LanguageGuard';
import { isBrowser } from 'react-device-detect';

const image = (color = 'red') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    content: "''",
    backgroundImage: 'url(/vectors/global.svg)',
    display: 'inline-block',
    marginRight: 5,
    height: 36,
    width: 36,
    position: 'relative',
    backgroundSize: '36px 36px',
  },
});

const customStyles = {
  menu: (provided: any, state: any) => ({
    ...provided,
    border: '1.5px solid #ef7c2e',
    color: 'white',
    backgroundColor: '#1E1E24',
    margin: 0,
    padding: 20,
    outline: 'none',
    boxShadow: 'none',
    borderBottomLeftRadius: '22px',
    borderBottomRightRadius: '22px',
    custom: {
      breakpoint: 2200,
      fontSize: 50,
      color: 'red',
    },
    ':active': {
      boxShadow: 'none',
      border: '1.5px solid #EF7C2E',
    },
    ':hover': {
      boxShadow: 'none',
      border: '1.5px solid #EF7C2E',
    },
  }),

  placeholder: (styles: any) => ({
    ...styles,
    ...image(),
    color: 'white',
  }),

  indicatorsContainer: (provided: any, state: any) => ({}),

  valueContainer: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '22px',
    color: 'white',
    display: 'flex',
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  }),

  option: (styles: any) => ({
    backgroundColor: 'transparent',
    padding: '10px 20px',
    color: 'white',
    outline: 'none',
    cursor: 'pointer',
    boxShadow: 'none',
    ':active': {
      ...styles[':active'],
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    ':hover': {
      ...styles[':active'],
      backgroundColor: 'transparent',
      color: '#EF7C2E',
      boxShadow: 'none',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },
  }),

  control: (provided: any, state: any) => ({
    ...provided,
    width: '100%',
    display: 'flex',
    border: state.isFocused ? '1.5px solid #EF7C2E' : '1.5px solid #FFFFFF',
    borderTopLeftRadius: '22px',
    borderTopRightRadius: '22px',
    alignItems: 'center',
    marginBottom: '40px',
    backgroundColor: '#1E1E24',
    outline: 'none',
    boxShadow: 'none',
    borderBottomLeftRadius: '22px',
    borderBottomRightRadius: '22px',
    ':focus': {
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    ':hover': {
      boxShadow: 'none',
      border: '1.5px solid #EF7C2E',
    },
    ':active': {
      boxShadow: 'none',
      border: '1.5px solid #EF7C2E',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },
  }),

  singleValue: (provided: any, state: any) => {
    const outline = 'none';
    const backgroundColor = 'transparent';
    const color = 'white';
    const boxShadow = 'none';
    const borderRadius = '22px';

    return { ...provided, outline, backgroundColor, color, boxShadow, borderRadius, ...image() };
  },

  input: (styles: any) => ({
    ...styles,
    ...image(),
  }),
};

const FooterSection = (props: any) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const { mobileBackgroundImage, backgroundImage, footerTopSection = true } = props;

  const options = [
    { value: 'Srbija', lang: 'sr', label: t('Serbia'), url: 'https://rs.vozzi.app/' },
    { value: 'Bih', lang: 'bs', label: t('Bosnia'), url: 'https://ba.vozzi.app/' },
    { value: 'Bugarska', lang: 'bg', label: t('Bulgaria'), url: 'https://bg.vozzi.app/' },
    { value: 'Engleski', lang: 'en', label: t('EU'), url: 'https://eu.vozzi.app/' },
    { value: 'Crna Gora', lang: 'me', label: t('Montenegro'), url: 'https://me.vozzi.app/' },
    { value: 'Makedonija', lang: 'mk', label: t('Macedonia'), url: 'https://mk.vozzi.app/' },
    { value: 'Rumunija', lang: 'ro', label: t('Romania'), url: 'https://ro.vozzi.app/' },
    { value: 'Slovenija', lang: 'si', label: t('Slovenia'), url: 'https://si.vozzi.app/' },
  ];

  const handleSelectChange = (option: any) => {
    setSelectedOption(option);
    if (isBrowser) {
      window.location.href = option.url;
    }
  };

  useEffect(() => {
    const currentLang = options.filter((item: any) => item.lang === lang);
    if (currentLang[0]) {
      setSelectedOption(currentLang[0]);
    }
  }, []);

  const navLinks = [
    {
      title: t('partnership_Nav'),
      subTitle: t('partnership_SubNav'),
      to: t('link_partners'),
    },
    {
      title: t('blog_Nav'),
      subTitle: t('blog_SubNav'),
      to: '/sr/blog/',
      visible: [{ country: 'rs', lang: 'sr' }],
    },
    {
      title: t('news_Nav'),
      subTitle: t('news_SubNav'),
      to: t('link_news'),
      visible: [{ country: 'rs', lang: 'sr' }, { country: 'me' }, { country: 'ba' }, { country: 'mk' }],
    },
    {
      title: t('promo_Nav'),
      subTitle: t('promo_SubNav'),
      to: '/sr/promo/',
      visible: [{ country: 'rs', lang: 'sr' }],
    },
    {
      title: t('about_Nav'),
      subTitle: t('about_SubNav'),
      to: t('link_about'),
    },
  ];

  return (
    <Footer>
      {footerTopSection ? (
        <FooterTopSection mobileBackgroundImage={mobileBackgroundImage} backgroundImage={backgroundImage}>
          <AboveTitle color="white">{t('neutral_ContactSectionSubHeading')}</AboveTitle>
          <Title color="white">
            <Trans i18nKey={'neutral_ContactSectionHeading'}>
              part 1<span className="break-on-md-and-lg">part 2</span>
            </Trans>
          </Title>
          <FlexWrapper className="footer-cards-holder" style={{ position: 'relative', bottom: '-60px' }}>
            <FooterCard>
              <FooterCardIcon style={{ height: 95 }} src="/icons/phone.svg" />
              <FooterCardTitle>{t('neutral_ContactSectionOCBoxHeading')}</FooterCardTitle>
              <FooterCardDescription style={{ marginBottom: 35 }}>{t('neutral_ContactSectionOCBoxDescription')}</FooterCardDescription>
              <FlexWrapper className="mobile-stay-flex" justifyContent="center">
                <LanguageGuard visible={[{ country: 'rs' }]}>
                  <BadgeOrange className="mobile-font-size-14" style={{ fontSize: 18, alignItems: 'center' }}>
                    <Img style={{ height: 20, position: 'relative', marginRight: 3 }} src="/vectors/call.svg" />
                    <RegularLink href={t('neutral_ContactSectionOCBoxPhoneOneLink')}>
                      {t('neutral_ContactSectionOCBoxPhoneOne')}
                    </RegularLink>
                  </BadgeOrange>
                </LanguageGuard>
                <BadgeOrange className="mobile-font-size-14" style={{ fontSize: 18, alignItems: 'center' }}>
                  <Img style={{ height: 20, position: 'relative', marginRight: 3 }} src="/vectors/call.svg" />{' '}
                  <RegularLink href={`tel:${t('neutral_ContactSectionOCBoxPhoneTwo')}`}>
                    {t('neutral_ContactSectionOCBoxPhoneTwo')}
                  </RegularLink>
                </BadgeOrange>
              </FlexWrapper>
            </FooterCard>
            <FooterCard>
              <FooterCardIcon style={{ height: 95 }} src="/icons/mail.svg" />
              <FooterCardTitle>{t('neutral_ContactSectionEmailBoxHeading')}</FooterCardTitle>
              <FooterCardDescription style={{ marginBottom: 35 }}>{t('neutral_ContactSectionEmailDescription')}</FooterCardDescription>
              <FlexWrapper className="mobile-stay-flex" justifyContent="center">
                <BadgeOrange className="mobile-font-size-14" style={{ fontSize: 18, alignItems: 'center' }}>
                  <RegularLink href="mailto:support@vozzi.app">{t('neutral_ContactSectionEmailAddress')}</RegularLink>
                </BadgeOrange>
              </FlexWrapper>
            </FooterCard>
          </FlexWrapper>
        </FooterTopSection>
      ) : null}
      <FooterBottomSection style={{ paddingTop: footerTopSection ? '' : '120px' }}>
        <FlexWrapperNoAlign className="mobile-margin-20 mobile-stay-flex mobile-align-items-center">
          <BottomSectionLeftCol>
            <FooterSectionLogo src="/vectors/footer-logo.svg" />
          </BottomSectionLeftCol>
          <BottomSectionRightCol>
            <BottomSectionRightColTitle>
              <Trans i18nKey={'footer_RightColumnHeading'}>
                part 1<span className="break-on-md-and-lg">part 2</span>
              </Trans>
            </BottomSectionRightColTitle>
          </BottomSectionRightCol>
        </FlexWrapperNoAlign>
        <FlexWrapperNoAlign>
          <BottomSectionLeftCol>
            <FooterBottomSectionLeftColDescription>{t('footer_LeftColumnDescription')}</FooterBottomSectionLeftColDescription>
          </BottomSectionLeftCol>
          <BottomSectionRightCol>
            <Select
              className="country-select"
              key={selectedOption}
              formatOptionLabel={(option, { context }) =>
                context === 'value' ? `${t('footer_RegionLabel')}: ${option.label}` : option.label
              }
              getOptionLabel={(option) => option.value}
              placeholder={t('footer_RegionLabel')}
              isSearchable={false}
              styles={customStyles}
              defaultValue={selectedOption}
              onChange={(option) => handleSelectChange(option)}
              options={options}
            />
          </BottomSectionRightCol>
        </FlexWrapperNoAlign>
        <FlexWrapperNoAlign>
          <BottomSectionLeftCol>
            <SocialIconsList>
              <SocialIconsListItem target="_blank" href={t('link_social_facebook')}>
                <FlexWrapper className="mobile-stay-flex">
                  <SocialIcon src="/icons/facebook.svg" />
                  Facebook
                </FlexWrapper>
                <SocialIconLink className="arrow" src="/vectors/arrow-right-white.svg" />
              </SocialIconsListItem>
              <SocialIconsListItem target="_blank" href={t('link_social_twitter')}>
                <FlexWrapper className="mobile-stay-flex">
                  <SocialIcon src="/icons/twitter.svg" />
                  Twitter
                </FlexWrapper>
                <SocialIconLink className="arrow" src="/vectors/arrow-right-white.svg" />
              </SocialIconsListItem>
              <SocialIconsListItem target="_blank" href={t('link_social_instagram')}>
                <FlexWrapper className="mobile-stay-flex">
                  <SocialIcon src="/icons/instagram.svg" />
                  Instagram
                </FlexWrapper>
                <SocialIconLink className="arrow" src="/vectors/arrow-right-white.svg" />
              </SocialIconsListItem>
              <SocialIconsListItem target="_blank" href={t('link_social_linkedIn')}>
                <FlexWrapper className="mobile-stay-flex">
                  <SocialIcon src="/icons/linkedin.svg" />
                  LinkedIn
                </FlexWrapper>
                <SocialIconLink className="arrow" src="/vectors/arrow-right-white.svg" />
              </SocialIconsListItem>
              <SocialIconsListItem target="_blank" href={t('link_social_youTube')}>
                <FlexWrapper className="mobile-stay-flex">
                  <SocialIcon src="/icons/youtube.svg" />
                  Youtube
                </FlexWrapper>
                <SocialIconLink className="arrow" src="/vectors/arrow-right-white.svg" />
              </SocialIconsListItem>
            </SocialIconsList>
          </BottomSectionLeftCol>
          <BottomSectionRightCol>
            <FlexWrapper className="mobile-stay-flex" alignItems="flex-start">
              <LinksList>
                <LinksListItem>{t('footer_LinksListTitleOne')}</LinksListItem>
                {navLinks
                  .filter((item) => item.visible?.some((item: any) => item.country.includes(COUNTRY)) || item.visible === undefined)
                  .map((item, index) => (
                    <LinksListItem key={index}>
                      <Link to={item.to}>{item.title}</Link>
                    </LinksListItem>
                  ))}
              </LinksList>
              <LinksList>
                <LinksListItem>{t('footer_LinksListTitleTwo')}</LinksListItem>
                <LinksListItem>{t('footer_EmailSupportHeadline')}</LinksListItem>
                <LinksListItem style={{ opacity: 0.5 }}>
                  <RegularLink href="mailto:support@vozzi.app">{t('footer_EmailSupport')}</RegularLink>
                </LinksListItem>
                <LinksListItem>Call Centar</LinksListItem>
                <LanguageGuard visible={[{ country: 'rs' }]}>
                  <LinksListItem style={{ opacity: 0.5 }}>
                    <RegularLink href="tel:19919">{t('footer_LinkCallCenterPhoneOne')}</RegularLink>
                  </LinksListItem>
                </LanguageGuard>
                <LinksListItem style={{ opacity: 0.5 }}>
                  <RegularLink href={`tel:${t('footer_LinkCallCenterPhoneTwo')}`}>{t('footer_LinkCallCenterPhoneTwo')}</RegularLink>
                </LinksListItem>
              </LinksList>
            </FlexWrapper>
            <FlexWrapperStart
              className="mobile-justify-content-space-between"
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '40px' }}
            >
              <LanguageGuard visible={[{ country: 'rs' }]}>
                <Link
                  target="_blank"
                  to="https://aaa.bisnode.si/certificate/download-digital-materials/?certificate=RS1000021151335-2023-GoldAAA&token=fafc9d28aac44b3c883dda759972e4ee&digitalMaterialType=DigitalCertificate&materialLanguage=sr-Latn-CS"
                >
                  <FooterIcon src="https://aaa.bisnode.si:443/banner/banner/?companyID=RS1000021151335&size=800&type=1&lang=sr-Latn-CS" />
                </Link>
              </LanguageGuard>
              <LanguageGuard visible={[{ country: 'eu' }]}>
                <Link
                  target="_blank"
                  to="https://aaa.bisnode.si/certificate/download-digital-materials/?certificate=RS1000021151335-2023-GoldAAA&token=fafc9d28aac44b3c883dda759972e4ee&digitalMaterialType=DigitalCertificate&materialLanguage=en-US"
                >
                  <FooterIcon src="https://aaa.bisnode.si:443/banner/banner/?companyID=RS1000021151335&size=800&type=1&lang=en-US" />
                </Link>
              </LanguageGuard>
              <Link target="_blank" to="https://profiles.dunsregistered.com/DunsRegisteredProfileAnywhere.aspx?key1=3150491&PaArea=Email">
                <FooterIcon src="/vectors/footer-duns-registered.svg" />
              </Link>
              <Link target="_blank" to="https://excellent-sme-serbia.safesigned.com/kov-technology-doo/">
                <FooterIcon src="/vectors/footer-excellent-sme.svg" />
              </Link>
            </FlexWrapperStart>
          </BottomSectionRightCol>
        </FlexWrapperNoAlign>
        <Divider />
        <FlexWrapper>
          <CopyRightText style={{ opacity: 0.5 }}>{t('footer_Copyright')}</CopyRightText>
          <CopyRightText>
            <FlexWrapper>
              <Link style={{ color: 'inherit' }} to={t('link_termsAndConditions')}>
                {t('footer_TermsOfService')}
              </Link>
              <p className="not-show-on-mobile" style={{ margin: '0px 5px' }}>
                &#8226;
              </p>
              <Link style={{ color: 'inherit' }} to={t('link_userDataPrivacyPolicy')}>
                {t('footer_LinkTwo')}
              </Link>
            </FlexWrapper>
          </CopyRightText>
        </FlexWrapper>
      </FooterBottomSection>
    </Footer>
  );
};

export default FooterSection;
