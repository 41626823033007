import { css } from '@emotion/core';

import { theme } from '../../../../../layouts/main.layout';

export const topNavigationMenu = css`
  .dropdown {
    position: relative;
    display: inline-block;

    &:hover {
      .dropdown-content {
        display: block;
        z-index: 1;
      }
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    margin-top: 3px;
    background-color: ${theme.colors.white};
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: -1;
  }

  .dropdown-content a {
    transition: ease 0.3s;
    color: ${theme.colors.black};
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;

    &:hover {
      background-color: ${theme.colors.vozziOrange};
      color: ${theme.colors.white};
    }
  }

  @media screen and (min-width: 1600px) {
    .dropdown-content {
      border-radius: 20px;
      overflow: hidden;
    }

    .dropdown-content a {
      font-size: 18px;
    }
  }

  @media screen and (min-width: 2200px) {
    .dropdown-content a {
      font-size: 24px;
    }
  }
`;

export const navBarLink = css`
  color: ${theme.colors.primaryTextGray};
  text-align: center;
  font-size: 13px;
  text-decoration: none !important;
  border-bottom: 3px solid transparent;

  :hover {
    color: ${theme.colors.vozziLightOrange};
    border-bottom: 3px solid rgb(244, 126, 32);
  }
  :not(:last-child) {
    margin-right: 45px;
  }
`;

export const navBarSpan = css`
  color: ${theme.colors.vozziLightOrange};
  text-align: center;
`;

export const navButtons = css`
  border-top: 6px solid transparent;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
  height: 128px;

  @media screen and (max-width: 768px) {
    height: auto;
  }

  @media screen and (max-width: 1200px) {
    padding: 0px;
    height: 94px;
  }

  @media screen and (max-width: 1024px) {
    height: 94px;
  }

  @media screen and (min-width: 1200px) {
    height: 94px;
  }

  @media screen and (min-width: 1400px) {
    height: 94px;
  }

  @media screen and (min-width: 2000px) {
    height: 94px;
  }

  @media screen and (min-width: 2200px) {
    height: 128px;
  }
`;

export const navBarScroll = css`
  overflow-y: auto;
  min-height: 800px;
`;
