import styled from '@emotion/styled';
import { SerializedStyles } from '@emotion/serialize';
import { css } from '@emotion/core';

export const LogoWithNavStyled = styled.div<{ absPos?: boolean }>`
  /* computed styles */

  ${(props): SerializedStyles | string =>
    props.absPos
      ? css`
          position: fixed;
          left: 0;
          top: 0;
          height: 128px;
          z-index: 9999999;
          box-shadow: 0px 0px 23px rgb(0 0 0 / 20%);
          width: 100%;

          @media screen and (max-width: 768px) {
            height: auto;
          }

          @media screen and (min-width: 1000px) {
            height: 94px;
          }

          @media screen and (min-width: 1200px) {
            height: 94px;
          }

          @media screen and (min-width: 1400px) {
            height: 94px;
          }

          @media screen and (min-width: 2200px) {
            height: 128px;
          }
        `
      : ``} @media screen and(max-width: 1024 px) {
    .simple-button-qr-desktop {
      display: none;
    }
  }
`;
